<template>
  <div>
    <h1>{{ $t('views.legal.h1_title') }}</h1>
    <h2>{{ $t('views.legal.h2_responsible') }}</h2>
    <Address />
    <h2>{{ $t('views.legal.h2_vat_id') }}</h2>
    <p>{{ legal.vatId }}</p>
    <h2>{{ $t('views.legal.h2_contact') }}</h2>
    <p>
      <i18n path="views.legal.p_contactform">
        <template #contactForm>
          <hyperlink :href="$t('hyperlinks.contact.href')" :aria-label="$t('hyperlinks.contact.purpose')">{{
            $t('views.legal.p_contact_form_contact_form')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
    <h2>{{ $t('views.legal.h2_disclaimer') }}</h2>
    <p>
      {{ $t('views.legal.p_disclaimer') }}
    </p>
    <h2>{{ $t('views.legal.h2_email') }}</h2>
    <p>{{ legal.emailAddress }}</p>
    <h2>{{ $t('views.legal.h2_phone') }}</h2>
    <p>{{ legal.phoneNumber }}</p>
    <h2>{{ $t('views.legal.h2_copyright') }}</h2>
    <p>
      <i18n path="views.legal.p_copyright">
        <template #here>
          <hyperlink :href="$t('hyperlinks.copyright.href')" :aria-label="$t('hyperlinks.copyright.purpose')">{{
            $t('views.legal.p_copyright_here')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
    <h2>{{ $t('views.legal.h2_online_dispute_resolution') }}</h2>
    <p>
      {{ $t('views.legal.p_online_dispute_resolution') }}
    </p>
    <h3>{{ $t('views.legal.h3_platform_url') }}</h3>
    <p><a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a></p>
    <h3>{{ $t('views.legal.h3_note') }}</h3>
    <i18n path="views.legal.p_note">
      <template #contactForm>
        <hyperlink :href="$t('hyperlinks.contact.href')" :aria-label="$t('hyperlinks.contact.purpose')">{{
          $t('views.legal.p_contact_form_contact_form')
        }}</hyperlink>
      </template>
    </i18n>
    <h2>{{ $t('views.legal.h2_privacy_statement') }}</h2>
    <p>{{ $t('views.legal.p_privacy_statement_1') }}</p>
    <p>{{ $t('views.legal.p_privacy_statement_2') }}</p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import Address from '@/components/Address.vue'
import BtnHome from '@/components/btn/Home'
import Hyperlink from '@/components/Hyperlink.vue'
import { legal } from '@/config/index.js'

export default {
  name: 'Legal',
  components: {
    Adslot,
    Address,
    BtnHome,
    Hyperlink,
  },
  computed: {
    legal() {
      return legal
    },
  },
}
</script>

<style lang="scss" scoped></style>
